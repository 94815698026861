import { uuid } from 'vue-uuid';
import store from '@/store/index';
import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_LARGE_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  function(config) {

    checkConfig(config);
    return config;
  },
  function(error) {
    // console.log('error Promise',error);
    return Promise.reject(error);
  },
);
function checkConfig(config) {
  config.headers['x-transaction-id'] = uuid.v1();
}

function httpErrorToast(data){
  const { type, title, message, button} = data;
  switch(type){
    case 'ALERT' : {
      const alertTitle = title ? `<strong>${title}</strong>` : '';
      const alertMessage = message ? message : '';
      store.dispatch('commonAlert/fetchAlertStart', {
        alertMsg: `${alertTitle}${alertMessage}`,
        closeBtnText: button.positive_button.text,
      });
      break;
    }
    case 'TOAST' : {
      store.dispatch('commonToast/fetchToastStart', 
      {
        msg : '이용에 문제가 생겼어요. 먼저 인터넷 연결 상태를 확인해 보고 다시 시도해 주세요.',
        type: 'error'
      });
      break;
    }
  }
}

http.interceptors.response.use(
  async function(response) {
    return response;
  },
  function(error) {
    // console.log('error',error);
    // // const { config } = error;
    // console.log('Response Error: ' + error.message);

    // 진짜 에러
    // alert(error.message);

    return Promise.reject(error);
  },
);

export function GET_REQUEST(path) {
  return new Promise((success, failed) => {
    http.get(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed(err)
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export function PUT_REQUEST(path, data) {
  return new Promise((success, failed) => {
    http.put(path, data).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        // const description = data.header.result.description
        const errCode = '200'
        const errMsg = 'errMsg'
        const description = 'description'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg + '\n' + description
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed()
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export function POST_REQUEST(path, data, config) {
  return new Promise((success, failed) => {
    http.post(path, data, config).then((response) => {
      console.log(response)
      if (response.status === 200) {
        const data = response.data;
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed(err)
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export function DELETE_REQUEST(path) {
  return new Promise((success, failed) => {
    http.delete(path).then((response) => {
      if (response.status === 200) {
        const data = response.data
        // const errCode = data.header.result.code
        // const errMsg = data.header.notice.message
        const errCode = '200'
        const errMsg = 'errMsg'

        if (errCode !== '200'){
          let result = {
            errCode : errCode,
            msg : errMsg
          }
          failed(result)

        }else{

          let result = {
            data : data.body
          }
          success(result)
          // console.log(result)
        }
      }

    }).catch((err) => {
      // let result = {
      //   errCode: err.response.data.code,
      //   msg: err.response.data.message.toString()
      // }
      failed()
      httpErrorToast(err.response.data.header.notice)
    })
  })
}

export default http;
