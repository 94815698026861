import { addDataListAll, setDataList } from '@/utils/selectDataUtils';

export default class HelpModel {
  constructor(){
    // 카테고리
    this.faqCategory= [];
    // 카테고리 selectbox용
    this.faqCategorySelectDataList= [];
    // faq 목록
    this.faqDataList = [];
    // faq top 10 목록
    this.faqTopTenList = [];

    // 선택한 카테고리
    this.selectedCategoryId = 'TOP';

    // 검색 키워드
    this.searchKeyWord = '';
    this.searched_history_list = [];
    this.searchDataList = [];

    this.searchParams = {
      page: 0,
      pageSize: 10,
      direction: "ASC",
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  // 카테고리
  setCategoryData(resultData){
    this.faqCategory = resultData;
    this.faqCategorySelectDataList = addDataListAll(setDataList(resultData,'id','name'),'TOP','질문 TOP')
  }
  // top 10
  setTopTenData(resultData){
    this.faqDataList = resultData;
    this.faqTopTenList = resultData;
  }
  getTopTenData(){
    this.faqDataList = this.faqTopTenList
  }
  // 페이징
  setPaginationData(obj){
    const { total, total_page } = obj;
    this.paginationData.totalCount = total;
    this.paginationData.totalPage = total_page;
  }
  // 카테고리별 목록
  setFaqDataListConcat(data){
    this.faqDataList = this.faqDataList.concat(data);
  }
  setCategoryHistoryData(id,obj){
    const { data, total, total_page } = obj;
    const categoryIndex = this.faqCategory.findIndex(item => item.id === id)
    const historyItem = {
      dataList: data,
      totalCount: total,
      totalPage: total_page
    }
    this.faqCategory[categoryIndex].dataList = data;
    this.faqCategory[categoryIndex].totalCount = total;
    this.faqCategory[categoryIndex].totalPage = total_page;
    this.getCategoryHistoryData(historyItem)
  }
  getIsCategoryHistoryData(id){
    return this.faqCategory.find(item => item.id === id && item.dataList);
  }
  getCategoryHistoryData(categoryHisgoryItem){
    this.faqDataList = categoryHisgoryItem.dataList;
    this.paginationData.totalCount = categoryHisgoryItem.totalCount;
    this.paginationData.totalPage = categoryHisgoryItem.totalPage;
  }
  // 키워드검색
  setSearchKeyWord(keyWord){
    this.searchKeyWord = keyWord;
  }
  setSearchDataListConcat(data){
    this.searchDataList = this.searchDataList.concat(data);
  }
  setSearchHistoryDataList(searchKeyWord,obj){
    const { data, total, total_page } = obj;
    const historyItem = {
      keyWord: searchKeyWord,
      dataList: data,
      totalCount: total,
      totalPage: total_page
    }
    this.searched_history_list.push(historyItem);
    this.getSearchHistoryDataList(historyItem);
  }
  getIsSearchHistoryDataList(keyWord){
    return this.searched_history_list.find(item => item.keyWord === keyWord);
  }
  getSearchHistoryDataList(searchHistoryItem){
    this.searchDataList = [];
    this.searchDataList = searchHistoryItem.dataList;
    this.paginationData.totalCount = searchHistoryItem.totalCount;
    this.paginationData.totalPage = searchHistoryItem.totalPage;
  }
  resetSearch(){
    this.searchKeyWord = '';
    this.resetSearcWithoutKeyword();
  }
  resetSearcWithoutKeyword(){
    this.searchDataList = [];
    this.searchParams.page = 0;
    this.paginationData.totalCount = 0;
    this.paginationData.totalPage = 0;
  }
  // setData(obj){
  //   const { id, name, deletable } = obj;
  //   this.id = id;
  //   this.name = name || '';
  //   this.deletable = deletable || true;
  // }
  // getData(){
  //   let obj = {
  //     id : this.id,
  //     name : this.name,
  //     deletable : this.deletable,
  //   }
  //   return obj;
  // }
}