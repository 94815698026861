const apiPath = {
  // FAQ
  FAQ_LIST: '/api/faq/v1/faqs', // 목록
  FAQ_CATEGORY: '/api/faq/v1/categories', // 카테고리 목록
  FAQ_TOP_LIST: '/api/faq/v1/faq/top', // top10 목록
  FAQ_CATEGORY_LIST: '/api/faq/v1/faqs/category/%s', // 카테고리별 목록 [id]
  FAQ_SEARCH: '/api/faq/v1/faqs/search/%s', // 검색 [keyword]

  
};
export default apiPath;
  // extension
  // Usage : apiPath.HO_NAME.format(arg1, arg2 ...)
  String.prototype.format = function() {
  return [...arguments].reduce((pattern,value) => pattern.replace(/%s/,value), this);
};