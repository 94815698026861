<template>
  <PageWithLayout
    :isTitle="true"
    :isBackBtn="viewModel.isBackBtn"
    :isBackWebEnd="!viewModel.model.searchKeyWord"
    @onClickBack="viewModel.onClickCloseSearch()">
    <div class="inner_section">
      <!-- <h2 class="tit_help">고객센터</h2> -->
      <div class="area_overflow">
         <div class="area_left area_search">
          <Input
            :placeholder="$poData.help_faq_search"
            :value.sync="viewModel.model.searchKeyWord"
            @onInput="val => viewModel.onInput(val)">
            <template v-slot:tfFrontIcon>
              <div class="area_icon"><IconSvg iconName="search" :size="24" iconColor="#232323"/></div>
            </template>
          </Input>
        </div>
        <div class="area_overflow">
          <!-- 키워드 검색 -->
          <template v-if="viewModel.model.searchKeyWord">
            <!-- <button class="btn_back" @click="viewModel.onClickCloseSearch()"><IconSvg iconName="arrow_right" :size="16" iconColor="#232323"/>처음으로 돌아가기</button> -->
            <template v-if="viewModel.model.searchDataList.length > 0">
              <FoldList
                :dataList="viewModel.model.searchDataList"
                listStyle="Q"
                title="question"
                desc="answer"
                :isSize="true"
                :searchParams.sync="viewModel.model.searchParams"
                :paginationData="viewModel.model.paginationData"
                @onSearch="viewModel.onClickMore('SEARCH')"/>
            </template>
            <p v-else class="desc_empty">검색 결과가 없습니다.</p>
          </template>
          <!-- 카테고리 -->
          <template v-else>
            <!-- 카테고리 선택 -->
            <ul class="list_quick">
              <li
                :class="{active : viewModel.model.selectedCategoryId === 'TOP'}">
                <button class="btn_quick" @click="viewModel.onClickCategroy('TOP')">질문 TOP</button>
              </li>
              <li
                v-for="categoryData in viewModel.model.faqCategory"
                :key="`category${categoryData.id}`"
                :class="{active : viewModel.model.selectedCategoryId === categoryData.id}">
                <button class="btn_quick" @click="viewModel.onClickCategroy(categoryData.id)">{{ categoryData.name }}</button>
              </li>
            </ul>
            <!-- <Selectbox
              v-else
              :dataList="viewModel.model.faqCategorySelectDataList"
              :value.sync="viewModel.model.selectedCategoryId"
              @update:value="value => viewModel.onClickCategroy(value)" /> -->
            <!-- 카테고리별 목록 -->
            <template v-if="viewModel.model.faqDataList.length > 0">
              <FoldList
                :dataList="viewModel.model.faqDataList"
                :orderNum="viewModel.model.selectedCategoryId === 'TOP' ? 'position' : ''"
                listStyle="Q"
                title="question"
                desc="answer"
                :isSize="viewModel.model.selectedCategoryId != 'TOP'"
                :searchParams.sync="viewModel.model.searchParams"
                :paginationData="viewModel.model.paginationData"
                @onSearch="viewModel.onClickMore('CATEGORY')"/>
            </template>
            <p v-else class="desc_empty">{{ viewModel.model.selectedCategoryId | convertIdToText(viewModel.model.faqCategorySelectDataList) }}의 FAQ가 없습니다.</p>
          </template>
          <!-- 1:1 문의 버튼 -->
          <button
            id="channelTalkBtn"
            ref="channelTalkBtn"
            :class="{ sticky : isSticky }">
            <span class="txt_label">1:1 문의</span>
            <span class="img_channel_talk"></span>
          </button>
        </div>
      </div>
    </div>
  </PageWithLayout>
</template>
<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
import Input from '@/components/common/input/Input';
import IconSvg from '@/components/common/icon/IconSvg';
import Selectbox from '@/components/common/selectbox/Selectbox';
import FoldList from '@/components/common/fold/FoldList'

// mixin
import ChannelServiceMixin from '@/mixins/ChannelServiceMixin'
import InterFaceMixin from '@/mixins/InterFaceMixin';
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';
// viewModel
import HelpVeiwModel from '@/views/help/viewModel/HelpVeiwModel'

export default {
  name: 'Help',
  mixins:[ ChannelServiceMixin, InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
    Input,
    IconSvg,
    Selectbox,
    FoldList
  },
  beforeMount(){
    this.viewModel.init(this.$route.query);
  },
  data(){
    return{
      viewModel: new HelpVeiwModel(),
      getIsMobile: true
    }
  },
  methods:{
    onClickBackKey(){
      if(this.viewModel.model.searchKeyWord){
        this.viewModel.onClickCloseSearch();
      }else{
        this.sendInterface('webEnd');
      }
    }
  }
}
</script>

<style scoped>
.inner_section{padding-bottom:160px}

.tit_help{padding-top:94px;font-weight:700;font-size:28px;line-height:42px;color:#111}
.tit_help + .area_overflow{margin-top:24px}

.area_search{float:none;margin:0;padding:16px 20px 0}
.area_search .tf_comm{width:100%}
.area_search .tf_comm >>> .area_icon{display:block;padding:16px 0 0 16px}
.area_search .tf_comm >>> .area_icon + input{padding-left:12px}

.area_search + .area_overflow .fold_comm:first-child{margin-top:24px}


/* .selectbox_comm{margin:24px 20px 0} */
.btn_back{margin:24px 20px 0;padding:12px 16px 12px 16px;border-radius:4px;border:1px solid #A6A4A4;font-weight:700;font-size:16px;line-height:24px;color:#232323}
.btn_back .icon_page_prev{margin:8px 6px 8px 2px}
.btn_back + .fold_comm{margin-top:16px}

.fold_comm{padding-bottom:0}

.list_quick{overflow:hidden;padding-top:24px;margin:-5px 16px}
.list_quick li{display:inline-block;padding:5px 4px;vertical-align:top}
.list_quick li .btn_quick{display:block;padding:6px 10px;border-radius:4px;font-weight:800;font-size:16px;line-height:24px;color:#999;letter-spacing:-0.02em}
.list_quick li.active .btn_quick{background-color:#F0EAFA;color:#8F69DD}
.list_quick + .fold_comm{margin-top:24px}

.desc_empty{margin-top:32px;padding:0 20px;font-weight:500;font-size:18px;line-height:36px;color:#999}
</style>