var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"selectElement",class:[
    'selectbox_comm',
    {
      active: _vm.isActive,
      disabled: _vm.isDisabled,
      selectbox_error: _vm.isError,
      valued: String(_vm.value).length > 0
    },
    _vm.size == 'small' ? 'selectbox_small' : ''
  ],style:({ maxWidth: _vm.maxWidth }),attrs:{"id":"selectboxComm"}},[_c('button',{staticClass:"btn_label",on:{"click":_vm.onClickSelectbox}},[(_vm.canMultiple)?[(String(_vm.value).length > 0)?_c('ul',{staticClass:"list_tag"},_vm._l((_vm.value),function(item){return _c('li',{key:`tag_${item}`},[_c('button',{staticClass:"btn_tag",on:{"click":function($event){$event.stopPropagation();return _vm.onClickTag(item)}}},[_vm._v(_vm._s(_vm.valueText(item))),_c('IconSvg',{attrs:{"iconName":"remove","size":"14","iconColor":"#aaa"}})],1)])}),0):[_vm._v(" "+_vm._s(_vm.placeholder)+" ")]]:_vm._e(),(!_vm.canMultiple)?[(_vm.thumnailKey)?_c('div',{staticClass:"img_option",style:({ 'background-image' : `url(${_vm.thumbnail})`})}):_vm._e(),_c('span',{staticClass:"txt_option"},[_vm._v(_vm._s(_vm.valueText(this.value) || _vm.placeholder))])]:_vm._e(),_c('IconSvg',{attrs:{"iconName":"arrow_fold","size":"32","iconColor":_vm.size == 'small' ? '#A6A4A4' : '#8F69DD'}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }