
import ChannelService from '@/utils/ChannelService'
import { mapGetters } from 'vuex';

const ChannelServiceMixin = {
  mounted(){
    this.setChannelService();
    // this.$nextTick(()=>{
    //   this.sectionSticky = this.$refs.channelTalkBtn;
    // });
    window.addEventListener("scroll", this.stopSticky);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.stopSticky);
  },
  data(){
    return{
      sectionSticky: undefined,
      isSticky: false,
      footerPadding: 84
    }
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getWindowHeight', 'getFooterHeight' ]),
  },
  methods: {
    setChannelService(){  
      // Boot Channel as a registered user
      ChannelService.boot({
        "pluginKey": "a697af3f-1bae-4eec-84eb-7f8c0cb35b08", //please fill with your plugin key
        "customLauncherSelector": "#channelTalkBtn",
        "hideChannelButtonOnBoot": true,
        "mobileMessengerMode":"iframe",
        "memberId": "", //fill with user id
        "profile": {
          "name": "", //fill with user name
          "mobileNumber": "", //fill with user phone number
          "CUSTOM_VALUE_1": "VALUE_1", //any other custom meta data
          "CUSTOM_VALUE_2": "VALUE_2"
        },
      });
    },
    showMessenger(){
      ChannelService.showMessenger();
    },
    stopSticky() {
      this.$nextTick(() => {
        var totalHeight = document.body.offsetHeight
        var windowTop = window.scrollY;

        // var sectionStickyHeight = this.sectionSticky.offsetHeight;
        // var sectionStickyTop = this.sectionSticky.offsetTop;

        var stopPoint = totalHeight - this.getWindowHeight - this.getFooterHeight;
        this.isSticky = windowTop > stopPoint ? true : false;
      });
    },
  }
}
export default ChannelServiceMixin;